@import "./transform.scss";

/* Definitions */
$outlinecolor: #da0023;
$outlinecolorlight: #f05544;
$greytextcolor: grey;
$greybackgroundcolor: rgb(240, 240, 240);
$borderDividerColor: rgb(201, 201, 201);
$contextwidth: 700px;
$naviSmallHeight: 50px;
$mobilebreak: 768px;

:export {
  outlinecolor: $outlinecolor
}