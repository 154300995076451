@import "../../shared.scss";

.schulklasse
{
  margin: auto;
  max-width: 900px;

  @media screen and (max-width: $mobilebreak) {
    padding: 0px;
  }

  .portrait
  {
    float: left;
    width: 20%;
    height: 280px;

    @media screen and (max-width: $mobilebreak) {
      max-width: 100%;
      width: 50% !important;
      padding: 0;
      // float: none;
      margin: auto;
      height: auto;
    }

    .image
    {
      margin: 10px;

      img
      {
        width: 100%;
        border-radius: 50%;
        border: 2px solid $outlinecolor;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -ms-box-sizing:border-box;
        box-sizing:border-box;
      }
    }

    p
    {
      margin: 5px;
      text-align: center;
    }

    .text
    {
      min-height: 70px;

      // @media screen and (max-width: $mobilebreak) {
      //   min-height: 70px;
      // }

      p.h1
      {
        font-family: 'Noto Serif', serif;
        color: $outlinecolor;
        font-size: 1.0em;
        font-weight: bold;
        @media screen and (max-width: $mobilebreak) {
          font-size: 0.8em;
        }
      }

      p.h2
      {
        color: $greytextcolor;
        font-size: 0.8em;
      }
    }

  }
}

.schulklasse.center
{
  margin: 0 auto;
  width: fit-content;

  .spacer
  {
    width: 25%;
    height: 20px;
    float: left;

    @media screen and (max-width: $mobilebreak) {
      display: none;
    }
  }
}