nav
{
  #navigationmobile
  {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    padding: 0;

    background-color: #fff;
    ul
    {
      width: 100%;
      position: absolute;
      padding: 0;
      left: 0;
      top: 50%;
      @include translate(0, -50%);

      text-align: center;

      li
      {
        margin: 10px 0 10px 0;

        a
        {
          font-size: 1.5em;
          color: $outlinecolor;
          text-decoration: none;
          font-weight: bold;
        }
      }

      li.active a
      {
        color: black;
      }
    }
  }
}

.navSmall
{

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after
  {
    background-color: #000;
  }

  .hamburger
  {
    top: 13px;
    right: 13px;
  }

}
