@import "./shared.scss";

body
{
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', 'Tahoma', sans-serif;
  font-size: 16px;
  line-height: 0; 
}

.App
{
  line-height: normal;
}

header
{
  position: relative;
}

header h1, header h2
{
  font-family: 'Noto Serif', serif;
  margin: 0;
}

.clearfix
{
  clear: both;
  display: table;
}

.bottomline
{
  border-bottom: 1px solid $borderDividerColor;
}