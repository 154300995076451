nav
{

  #navigationdesktop ul
  {
    position: absolute;
    display: inline-block;
    
    right: 50px;
    top: 50%;
    
    list-style-type: none;
    margin: 0;

    @include translate(0, -50%);

    li
    {
      float: left;
      padding: 0 10px 0 10px;
      font-weight: bold;

      a
      {
        color: #fff;
        text-decoration: none;
      }


      a:hover, a:active
      {
        color: $outlinecolor;
      }
    }

    li.active a
    {
      color: $outlinecolor;
    }

  }
}

nav.navSmall #navigationdesktop ul a
  {
    color: #636363;
}