@import "../../shared.scss";

.icons
{
  width: 600px;
  max-width: 100%;
  padding: 50px;
  display: inline-block;

  @media screen and (max-width: $mobilebreak) {
    padding: 0px;
  }

  .portrait
  {
    float: left;
    width: 33%;

    @media screen and (max-width: $mobilebreak) {
      max-width: 100%;
      width: 80% !important;
      padding: 0;
      float: none;
      margin: auto;
    }

    .image
    {
      margin: 10px;

      img
      {
        width: 100%;
        border-radius: 50%;
        border: 4px solid $outlinecolor;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -ms-box-sizing:border-box;
        box-sizing:border-box;
      }
    }

    p
    {
      margin: 5px;
      text-align: center;
    }

    p.h1
    {
      font-family: 'Noto Serif', serif;
      color: $outlinecolor;
      font-size: 1.0em;
      font-weight: bold;
    }

    p.h2
    {
      color: $greytextcolor;
      font-size: 0.8em;
    }

  }
}